<template>
  <div class="Modal">
    <img :src="require(`@/assets/images/header-lights_1440.png`)" alt="bg" class="Modal-Bg">
    <img
        src="@/assets/images/close.svg"
        alt="close"
        class="Close"
        @click="$modal.hide('Modal')"
    >
    <BaseH3 class="Title--dark Modal-Title">
      <template v-if="isLogIn">
        {{$t('modal.login')}}
      </template>
      <template v-else>
        {{$t('modal.signUp')}}
      </template>
    </BaseH3>
    <BaseSubtitle class="Modal-Subtitle">
      {{$t('modal.text')}}
    </BaseSubtitle>
    <template
        v-for="(info, i) in modalInfo"
        >
      <BaseLink
          :key="i"
          :button-grey="true"
          :link="isLogIn ? info.linkLogIn : info.linkSignUp"
          class="Modal-Link"
      >
        <div class="Modal-LinkInfo">
          <img :src="require(`@/assets/images/${info.logo}`)" alt="logo" class="Modal-LinkLogo">
          <span class="Modal-LinkSign">
          <template v-if="isLogIn">
            {{$t('modal.login')}}
          </template>
          <template v-else>
            {{$t('modal.signUp')}}
          </template>
          <img
              src="@/assets/images/arrow-right.svg"
              alt="arrow"
              class="Arrow"
          >
        </span>
        </div>
      </BaseLink>
    </template>

  </div>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    modalInfo: {
      type: [Object, Array, String],
      default: () => {
      }
    },
    isLogIn: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss">
.Modal {
  position: relative;
  padding: 35px 19px 90px;
  background-image: url(~@/assets/images/body-bg.png);
  background-color: var(--color-body);
  text-align: center;

  @media (min-width: $screen-m) {
    padding: 65px 57px 140px;
  }

  &-Bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-Subtitle {
    margin-bottom: 46px;
    padding: 0 20px;
    font-size: 14px;
    text-align: center !important;

    @media (min-width: $screen-m) {
      margin-bottom: 90px;
      padding: 0;
      font-size: 20px;
    }
  }

  &-Link {
    width: 100%;
    height: 51px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      height: 81px;
    }
  }

  &-LinkInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 12px;

    @media (min-width: $screen-l) {
      padding: 0 19px;
    }
  }

  &-LinkLogo {
    width: 118px;
    margin-right: 20px;

    @media (min-width: $screen-s) {
      width: 172px;
    }
  }

  &-LinkSign {
    font-size: 14px;
    @media (min-width: $screen-l) {
      display: flex;
      align-items: center;
      font-size: 22px;
    }
    .Arrow {
      width: 15px;
      margin-left: 5px;
      @media (min-width: $screen-l) {
        width: auto;
        margin-left: 10px;
      }
    }
  }

  .Close {
    width: 15px;
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;

    @media (min-width: $screen-m) {
      width: auto;
      top: 40px;
      right: 40px;
    }
  }
}
</style>
