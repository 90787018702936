<template>
  <section class="About">
    <div class="Wrapper">
      <div class="About-Content">
        <div class="About-Info">
          <img src="@/assets/images/about-text-bg.png" alt="bg" class="About-InfoBg">
          <div class="About-InfoContent">
            <BaseH3 class="Title--dark About-Title" :class="{'About-Title-Margin': this.$i18n.locale === 'pl'}">
              {{ $t('home-page.about.title') }}
            </BaseH3>
            <p class="Text Text--ghost Text--small About-Text" >
              {{ $t('home-page.about.text') }}
            </p>
          </div>
        </div>
        <div class="About-ImgBox">
          <img src="@/assets/images/girl.png" alt="girl" class="About-Img">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About"
}
</script>

<style lang="scss">

.About {
  padding: 50px 0 33px;

  @media (min-width: $screen-m) {
    padding: 75px 0 45px;
  }

  @media (min-width: $screen-l) {
    padding: 100px 0 51px;
  }

  @media (min-width: $screen-xl) {
    padding: 200px 0 51px;
  }


  &-Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-m) {
     flex-direction: row;
    }
  }


  &-Info {
    text-align: left;

    @media (min-width: $screen-m) {
      width: 470px;
    }

    @media (min-width: $screen-l) {
      position: relative;
      top: -67px;
      left: 30px;
      width: 670px;
      z-index: 1;
    }

    @media (min-width: $screen-xl) {
      width: 815px;
    }

  }

  &-InfoContent {

    @media (min-width: $screen-l) {
      padding: 27px 85px 39px 38px;
    }
    @media (min-width: $screen-xl) {
      padding: 47px 165px 49px 48px;
    }
  }

  &-Title {
    margin-bottom: 25px;
  }

  &-InfoBg {
    display: none;
    @media (min-width: $screen-l) {
      display: block;
      position: absolute;
      z-index: -1;
    }
  }

  &-ImgBox {
    width: 288px;
    height: 288px;
    position: relative;
    margin-top: 40px;
    background-color: var(--color-bg-main);
    border: 2px solid var(--color-border-light);
    border-radius: 50%;

    @media (min-width: $screen-m) {
      width: 300px;
      height: 300px;
    }

    @media (min-width: $screen-xl) {
      width: 430px;
      height: 430px;
    }
  }

  &-Img {
    position: absolute;
    width: 269px;
    top: -11%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: $screen-m) {
      width: auto;
    }

    @media (min-width: $screen-xl) {
      top: -10%;
    }
  }

}

.About-Title-Margin {
  margin-bottom: 15px;
}
</style>
