<template>
  <a :href="link" target="_blank" class="BaseButton">
       <span class="BaseButton-Content"
             :class="[
              { 'BaseButton--colored-grey': buttonGrey },
              { 'BaseButton--colored-red': buttonRed },
            ]">
      <span :class="{ 'BaseButton--margin': image }">
        <slot />
      </span>
      <img v-if="image"
           :src="require(`@/assets/images/${image}`)"
           alt=""
           class="BaseButton-Img"
      >
    </span>
    <span class="BaseButton-Border"></span>
  </a>
</template>

<script>
export default {
  name: "BaseLink",
  props: {
    link: {
      type: String,
      default: ''
    },
    buttonGrey: {
      type: Boolean,
      default: false,
    },
    buttonRed: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      required: false,
      default: ""
    }
  },
}
</script>

<style scoped>

</style>