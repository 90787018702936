import en from './en.json'
import pl from './pl.json'
import tr from './tr.json'

import Cookies from 'js-cookie';

export const defaultLocale = Cookies.get('locale');

export const languages = {
    en, pl, tr
}
