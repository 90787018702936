import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cookies from 'js-cookie';

Vue.use(VueRouter)

const TermsConditions = () => import(/* webpackChunkName: "TermsConditions" */ '../views/TermsConditions.vue')
const CookiePrivacyPolicy = () => import(/* webpackChunkName: "CookiePolicy" */ '../views/CookiePrivacyPolicy.vue')
const ContactUs = () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs.vue')
const FAQ = () => import(/* webpackChunkName: "FAQ" */ '../views/FAQ.vue')

const routes = [
  {
    path: '/',
    redirect: Cookies.get('locale')
  },
  {
    path: '/:lang?',
    name: 'Home',
    component: Home
  },
  {
    path: '/terms-and-conditions/:lang?',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/cookie-policy/:lang?',
    name: 'CookiePolicy',
    component: CookiePrivacyPolicy
  },
  {
    path: '/privacy-policy/:lang?',
    name: 'PrivacyPolicy',
    component: CookiePrivacyPolicy
  },
  {
    path: '/contact-us/:lang?',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/faq/:lang?',
    name: 'FAQ',
    component: FAQ,
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        inline: 'start',
        behavior: 'smooth',
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    };
  },
  routes
})

export default router
