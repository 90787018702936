<template>
  <div class="SupportWrap">
    <div
        v-for="(item, i) in supportInfo"
        :key="i"
        class="Support"
    >
      <div class="Support-Top">
        <p
            v-for="(text, i) in item.text"
            :key="i"
            class="Text Text--dark Text--small Support-Text"
            v-html="text"
        ></p>
      </div>
      <ul
          v-for="(ul, i) in item.lists"
          :key="'A' + i"
          class="Support-List"
      >
        <li class="Support-Item">
          <span
              class="Text Text--large Support-Title"
          >{{ ul.title }}</span>
          <template v-if="ul.text">
            <div class="Support-ListText">
              <p
                  v-for="(text, index) in  ul.text"
                  :key="index"
                  class="Text Text--ghost Text--small Support-ItemText"
                  v-html="text"
              ></p>
            </div>
          </template>
          <template v-if="ul.list">
            <ul class="Support-ListInner">
              <li
                  v-for="(item, id) in  ul.list"
                  :key="id"
                  class="Text Text--ghost Text--small Support-ItemInner"
                  v-html="item"
              ></li>
            </ul>
          </template>
          <template v-if="ul.listLetter">
            <ul class="Support-ListLetter">
              <li
                  v-for="(letter, ind) in  ul.listLetter"
                  :key="ind"
                  class="Text Text--ghost Text--small Support-ItemLetter"
                  v-html="letter"
              ></li>
            </ul>
          </template>
          <template v-if="ul.listDisc">
            <ul class="Support-ListDisc">
              <li
                  v-for="(disc, dis) in  ul.listDisc"
                  :key="dis"
                  class="Text Text--ghost Text--small Support-ItemDisc"
                  v-html="disc"
              ></li>
            </ul>
          </template>
          <template v-if="ul.text1">
            <div class="Support-ListText">
              <p
                  v-for="(text, index) in  ul.text1"
                  :key="index"
                  class="Text Text--ghost Text--small Support-ItemText"
                  v-html="text"
              ></p>
            </div>
          </template>
          <template v-if="ul.listDisc1">
            <ul class="Support-ListDisc">
              <li
                  v-for="(disc, dis) in  ul.listDisc1"
                  :key="dis"
                  class="Text Text--ghost Text--small Support-ItemDisc"
                  v-html="disc"
              ></li>
            </ul>
          </template>
          <template v-if="ul.text2">
            <div class="Support-ListText">
              <p
                  v-for="(text, index) in  ul.text2"
                  :key="index"
                  class="Text Text--ghost Text--small Support-ItemText"
                  v-html="text"
              ></p>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSupport",
  props: {
    supportInfo: {
      type: [Object, Array],
      default: ()=>{}
    },
  },
}
</script>

<style lang="scss">
.Support {
  counter-reset: list;
  &-Top {
    margin-bottom: 60px;
  }

  &-Text {
    margin-bottom: 20px;
  }


  &-Title {
    display: inline-block;
    margin-bottom: 20px;
    font-family: 'KomikaTitle', sans-serif, monospace;
    font-size: 18px;

    @media (min-width: $screen-m) {
      font-size: 21px;
    }

    @media (min-width: $screen-xl) {
      font-size: 24px;
    }
  }

  &-List {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-Item {
    margin-bottom: 20px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: counter(list) '. ';
      counter-increment: list;
      font-size: 16px;
      font-family: 'KomikaTitle', sans-serif, monospace;

      @media (min-width: $screen-m) {
        font-size: 19px;
      }

      @media (min-width: $screen-xl) {
        font-size: 22px;
      }
    }
  }

  &-ListInner {
    counter-reset: listInner;
  }

  &-ItemInner {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: counters(list, '.') '.' counters(listInner, '.') '. ';
      counter-increment: listInner;
      color: var(--color-text-main);
      font-family: 'KomikaTitle', sans-serif, monospace;

      font-size: 16px;
      @media (min-width: $screen-m) {
        font-size: 19px;
      }

      @media (min-width: $screen-xl) {
        font-size: 22px;
      }
    }
  }

  &-ListLetter {
    counter-reset: listLetter;
  }

  &-ItemLetter {
    margin-bottom: 20px;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: counter(listLetter, lower-alpha) ') ';
      counter-increment: listLetter;
      color: var(--color-text-main);
    }
  }

  &-ListDisc {
    margin: 20px 0 20px 40px;
  }

  &-ItemDisc {
    margin-bottom: 20px;
    list-style-type: disc;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>