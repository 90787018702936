<template>
  <button class="BaseButton" v-bind="$attrs">
    <span class="BaseButton-Content"
          :class="[
              { 'BaseButton--colored-grey': buttonGrey },
              { 'BaseButton--colored-red': buttonRed },
            ]">
      <span :class="{ 'BaseButton--margin': image }">
        <slot />
      </span>
      <img v-if="image"
          :src="require(`@/assets/images/${image}`)"
           alt=""
           class="BaseButton-Img"
      >
    </span>
    <span class="BaseButton-Border"></span>
  </button>
</template>

<script>
import {showModal} from '../assets/utils/utils';

export default {
  inheritAttrs: false,
  name: "BaseButton",
  props: {
    buttonGrey: {
      type: Boolean,
      default: false,
    },
    buttonRed: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    showModal
  }
}
</script>

<style lang="scss">

</style>