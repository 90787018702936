<template>
  <p class="Text Text--large Subtitle">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "BaseSubtitle"
}
</script>

<style lang="scss">
.Subtitle {
  font-family: 'KomikaText', sans-serif, monospace;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  text-align: left;
  letter-spacing: 0.03em;
  color: #202020;
  margin-bottom: 51px;

  @media (min-width: $screen-m) {
    text-align: center;
    margin-bottom: 60px;
  }

  @media (min-width: $screen-l) {
    font-family: 'KomikaTitle', sans-serif, monospace;
    font-size: 28px;
    line-height: 100%;
    text-align: center;
  }

  @media (min-width: $screen-xl) {
    margin-bottom: 100px;
  }
}
</style>