<template>
  <div class="MainMenu"
       :class="{ 'MainMenu--scrolled': documentIsScrolled }"
  >
    <div
        class="Wrapper MainMenu-Box"
    >
      <Logo/>
      <BaseButton
          v-if="$screen.width < 961"
          @click.native="show = !show"
          class="MainMenu-Burger Order"
      >
        <img src="@/assets/images/hamburger.svg" alt="open menu">
      </BaseButton>
      <NavBar v-if="$screen.width > 960"/>
      <div
          v-if="$screen.width > 960"
          class="MainMenu-Btns"
      >
        <BaseButton
            class="MainMenu-Btn"
            @click.native="showModal(getModalList, true), show = false"
        >
          {{ $t('home-page.header.header-btns.login') }}
        </BaseButton>
        <BaseButton
            class="MainMenu-Btn"
            :button-grey="true"
            @click.native="showModal(getModalList), show = false"
        >
          {{ $t('home-page.header.header-btns.sign-up') }}
        </BaseButton>
      </div>
      <div class="box box--mobile">
        <button type="button" @click="setLocale('en')" :class="{active: activeLocale('en')}">
          EN
        </button>
        <button type="button" @click="setLocale('pl')" :class="{active: activeLocale('pl')}">
          PL
        </button>
        <button type="button" @click="setLocale('tr')" :class="{active: activeLocale('tr')}">
          TR
        </button>
      </div>

      <template v-if="$screen.width < 961">
        <transition name="fade">
          <AsideMenu
              v-show="show" @close-menu="closeMenu"/>
        </transition>
      </template>

    </div>

  </div>
</template>

<script>
import Logo from "./Logo";
import NavBar from "./NavBar";
import AsideMenu from "./AsideMenu";
import {showModal} from "../assets/utils/utils";
import {mapGetters} from "vuex";
import Cookies from 'js-cookie';

export default {
  name: "MainMenu",
  components: {AsideMenu, NavBar, Logo},
  data() {
    return {
      documentIsScrolled: false,
      show: false,
      logIn: true,
    }
  },
  computed: {
    ...mapGetters(['getModalList'])
  },
  methods: {
    showModal,
    onScroll() {
      this.documentIsScrolled = window.scrollY > 35;
    },
    closeMenu() {
      this.show = !this.show
    },

    activeLocale(locale) {
      if (this.$i18n.locale === locale) {
        return this.$i18n.locale = locale
      }
    },

    setLocale(locale) {
      this.$i18n.locale = locale;
      Cookies.set('locale', locale)
      this.$router.push({
        params: {lang: this.$i18n.locale}
      })
      this.$router.go(0)
    }
  },

  mounted() {
    if (window.scrollY > 35) this.documentIsScrolled = true;
    window.addEventListener('scroll', this.onScroll, {passive: true});
  }
}
</script>

<style lang="scss">
.MainMenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  padding: 20px 0;
  background-color: transparent;
  transition: .5s;

  @media (min-width: $screen-l) {
    padding: 32px 0;
  }

  &--scrolled {
    background-image: url(~@/assets/images/body-bg.png);
    background-color: var(--color-body);
    box-shadow: 0 5px 5px var(--color-text-ghost);
    @media (min-width: $screen-l) {
      padding: 20px 0;
    }
  }

  &-Box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 954px) {
      justify-content: flex-start;
    }

    @media (max-width: 764px) {
      justify-content: space-between;
    }
  }

  &-Btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-xs) {
      justify-content: left;
    }
  }

  &-Btn {

    @media (min-width: $screen-l) {
      width: 126px;
      height: 44px;

      &:last-child {
        margin-left: 22px;
      }
    }
  }

  &-Burger {
    width: 32px;
    height: 32px;
    @media (max-width: 954px) {
      margin-right: 50px;
    }
    @media (max-width: 767px) {
      order: 1;
      margin-right: 0;
    }


    @media (min-width: $screen-m) {
      width: 42px;
      height: 42px;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .box {
    display: flex;
    column-gap: 25px;
    @media (max-width: 1000px) {
      column-gap: 15px;
    }

    @media (max-width: 767px) {
      &--mobile {
        display: none;
      }

      &--aside {
        button {
          font-size: 20px !important;
        }
      }
    }

    button {
      cursor: pointer;
      font-size: 18px;
      transition: all 0.2s;
      position: relative;
      @media (max-width: 1000px) {
        font-size: 16px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 5px;
        background-color: var(--color-text-dark);
        transition: .3s all;

        &:hover {
          color: var(--color-text-dark);
          opacity: 0.5;
        }
      }

      &.active::before {
        width: 100%;
      }
    }
  }


}

</style>
