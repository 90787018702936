<template>
  <section class="TheHeader">
    <img :src="require(`@/assets/images/header-lights_${getBgWidth}.png`)" alt="bg" class="TheHeader-Bg">
    <div class="Wrapper TheHeader-Wrapper">
      <h1
          class="Title--h1 Title--dark TheHeader-Title"
          :class="{
        'font-bosk': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr',
        'maxw875': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr',
        'fz80': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr',
      }"
      >
        <span class="TheHeader-Subtitle" :class="{'fz60': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr',}">
          {{ $t('home-page.main-screen.title') }}
        </span>
        <span v-if="$screen.width < 960">TRUSTED</span>
        <span v-else>
          {{ $t('home-page.main-screen.text') }}
        </span>
      </h1>
      <BaseLink
          link="https://track.betmenaffiliates.com/signup/"
          :button-red="true"
          image="red-arrow.svg"
          class="TheHeader-Btn"
      >
        {{ $t('home-page.main-screen.link') }}
      </BaseLink>
      <img :src="require(`@/assets/images/man${getManMobile}.png`)" alt="man" class="TheHeader-Man">
      <img src="@/assets/images/boom.png" alt="" class="TheHeader-Boom">
      <div class="TheHeader-Benefits">
        <span class="TheHeader-BenefitsText">
           {{ $t('home-page.main-screen.text2') }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TheHeader",
  computed: {
    getBgWidth() {
      return this.$screen.width < 1440 ? '1440' : '1920'
    },
    getManMobile() {
      return this.$screen.width < 768 ? '-mobile' : ''
    }
  }
}
</script>

<style lang="scss">
.TheHeader {
  position: relative;
  padding: 299px 0 59px;
  background-image: url(~@/assets/images/header-bg-mobile.png);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 6px solid var(--color-bg-dark);
  overflow: hidden;

  @media (min-width: $screen-m) {
    padding: 131px 0 81px;
    background-image: url(~@/assets/images/header-bg.png);
  }

  @media (min-width: $screen-l) {
    padding: 150px 0 120px;
  }

  @media (min-width: $screen-xl) {
    padding: 231px 0 187px;
  }

  &-Bg {
    display: none;

    @media (min-width: $screen-m) {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  &-Wrapper {
    position: relative;
  }

  &-Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    font-family: KomikaTitle, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 52px;
    letter-spacing: 0.03em;
    line-height: 1;
    text-shadow: 4px 4px 0px var(--color-bg-red);
    color: var(--color-text-gold2);
    -webkit-text-stroke: 1px var(--color-text-dark);

    @media (min-width: $screen-m) {
      align-items: flex-start;
      margin-bottom: 55px;
    }

    @media (min-width: $screen-l) {
      margin-bottom: 73px;
      font-size: 82px;
      letter-spacing: 0;
      text-shadow: 6px 6px 0px var(--color-bg-red);
      -webkit-text-stroke: 2px var(--color-text-dark);
    }

    @media (min-width: $screen-xl) {
      font-size: 131px;
    }
  }

  &-Subtitle {
    margin-top: 20px;
    font-size: 36px;
    letter-spacing: 0.03em;
    order: 2;

    @media (min-width: $screen-l) {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 60px;
      letter-spacing: 0;
      order: 0;
    }

    @media (min-width: $screen-xl) {
      font-size: 85px;
    }
  }


  &-Btn {
    width: 226px;
    height: 56px;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: $screen-m) {
      left: 0;
      transform: translate(0, 0);
    }

    @media (min-width: $screen-l) {
      width: 210px;
      height: 60px;
    }

    @media (min-width: $screen-xl) {
      width: 224px;
      height: 64px;
    }
  }

  &-Man {
    position: absolute;
    right: 0;
    bottom: 75px;

    @media (min-width: $screen-m) {
      width: 490px;
      right: 40px;
      bottom: -81px;
    }

    @media (min-width: $screen-l) {
      width: 590px;
      right: 0;
      bottom: -140px;
    }

    @media (min-width: $screen-xl) {
      width: auto;
      right: 30px;
      bottom: -187px;
    }
  }

  &-Boom {
    position: absolute;
    width: 74px;
    top: -196px;
    right: 0;
    transform: rotateZ(45deg);

    @media (min-width: $screen-m) {
      width: 120px;
      top: -46px;
      right: 30px;
    }

    @media (min-width: $screen-l) {
      top: -5px;
      right: 0;
    }

    @media (min-width: $screen-xl) {
      width: auto;
      top: -25px;
      right: -40px;
    }
  }

  &-Benefits {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 145px;
    top: -200px;
    right: 149px;
    background-image: url(~@/assets/images/cloud.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: $screen-m) {
      width: 205px;
      height: 165px;
      top: -90px;
      right: 255px;
    }

    @media (min-width: $screen-l) {
      width: 265px;
      height: 205px;
      top: -45px;
      right: 255px;
    }

    @media (min-width: $screen-xl) {
      width: 365px;
      height: 305px;
      top: -145px;
      right: 375px;
    }

    @media (min-width: $screen-xxl) {
      top: -145px;
      right: 395px;
    }
  }

  &-BenefitsText {
    width: 70px;
    margin: 0 0 37px 10px;
    font-size: 7px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--color-text-dark);
    transform: rotateZ(-43.2deg);

    @media (min-width: $screen-m) {
      margin: 0 0 50px 10px;
      font-size: 9px;
    }

    @media (min-width: $screen-l) {
      width: 136px;
      font-size: 13px;
      transform: rotateZ(-36.61deg);
    }

    @media (min-width: $screen-xl) {
      width: 166px;
      margin: 0 0 80px 17px;
      font-size: 17px;

    }
  }

}
</style>
