<template>
  <div class="BenefitsContent">
    <swiper class="BenefitsContent-Slider" :options="swiperOption">
      <swiper-slide
          v-for="(benefit, $benefitIndex) in benefits"
          :key="$benefitIndex"
          :style="{backgroundImage: `url(${benefit.slideBg})`}"
          class="BenefitsContent-Slide"
      >
        <img
            :src="require(`@/assets/images/${benefit.icon}`)"
            alt="icon"
            class="BenefitsContent-Icon"
            :class="benefit.iconClass"
        >
        <div
            class="Text BenefitsContent-Text"
            :class="benefit.textClass"
        >
          {{ benefit.text }}
        </div>

      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import {Pagination} from 'swiper'

export default {
  components: {},
  props: {
    benefits: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      swiperOption: {
        modules: [Pagination],
        spaceBetween: 20,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '</span>';
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import 'Benefits';
</style>