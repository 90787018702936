<template>
  <section class="WorkOrder">
<!--    <img v-show="$screen.width < 768" src="@/assets/images/gambler-mobile.png" alt="gambler" class="WorkOrder-Img Mobile">-->
    <div class="Wrapper WorkOrder-Wrapper">
      <div class="WorkOrder-Info">
        <img
            v-if="$screen.width > 960"
            src="@/assets/images/work-bg.png"
             alt="bg"
             class="WorkOrder-InfoBg">
        <BaseH3 class="Title--dark WorkOrder-Title">
          {{ $t('home-page.workOrder.title') }}
        </BaseH3>
        <img
            v-if="$screen.width < 960"
            src="@/assets/images/gambler.png"
             alt="gambler"
             class="WorkOrder-Img Mobile">
        <div class="WorkOrder-Content">

          <ul class="WorkOrder-List">
            <li
                v-for="(item, i) in getWorkOrderList"
                :key="i"
                class="Text Text--middle WorkOrder-Item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <img
          v-if="$screen.width > 960"
          src="@/assets/images/gambler.png"
           alt="gambler"
           class="WorkOrder-Img Desctop">
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "WorkOrder",
  computed: {
    ...mapGetters(['getWorkOrderList'])
  }
}
</script>

<style lang="scss">
.WorkOrder {
  padding: 40px 0 57px;
  position: relative;

  @media (min-width: $screen-m) {
    overflow: hidden;
  }

  @media (min-width: $screen-l) {
    padding: 60px 0 90px;
  }

  @media (min-width: $screen-xl) {
    padding: 40px 0 200px;
  }

  &-Info {
    position: relative;

    @media (min-width: $screen-l) {
      padding: 59px 25px 66px 90px;
    }


  }

  &-InfoBg {
    width: 700px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;

    @media (min-width: $screen-xl) {
      width: 780px;
    }

    @media (min-width: $screen-xxl) {
      width: auto;
    }
  }

  &-Title {
    margin-bottom: 33px;

    @media (min-width: $screen-l) {
      margin-bottom: 78px;
    }
  }

  &-List {
    counter-reset: list;

    @media (min-width: $screen-l) {
      width: 418px;
    }
  }

  &-Item {
    position: relative;
    margin-bottom: 58px;
    padding-left: 18px;
    z-index: 1;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      margin-bottom: 70px;
      padding-left: 35px;
    }

    &:before {
      content: counter(list);
      counter-increment: list;
      position: absolute;
      z-index: -1;
      top: -55px;
      left: 0;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 88px;
      line-height: 1.2;
      letter-spacing: 0.095em;
      color: var(--color-text-ghost3);

      @media (min-width: $screen-m) {
        top: -70px;
      }

      @media (min-width: $screen-l) {
        top: -60px;
        font-size: 112px;
      }
    }
  }

  &-Content {
    position: relative;
  }

  .Mobile {
    margin-bottom: 60px;

    @media (min-width: $screen-m) {
      width: 400px;
    }
  }

  .Desctop {

    @media (min-width: $screen-l) {
      position: absolute;
      width: 65%;
      bottom: 30px;
      right: -140px;
      z-index: -3;
    }

    @media (min-width: $screen-xl) {
      right: -50px;
      bottom: 0;
    }

    @media (min-width: $screen-xl) {
      width: auto;
    }
  }

}
</style>
