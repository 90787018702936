<template>
  <div class="AsideMenu">
    <div class="Wrapper">
      <div class="AsideMenu-Header">
        <Logo/>
        <BaseButton
            v-if="$screen.width < 961"
            @click.native="$emit('close-menu')"
            class="MainMenu-Burger Order"
        >
          <img src="@/assets/images/close.svg" alt="close menu">
        </BaseButton>
      </div>
      <NavBar
          @click.native="$emit('close-menu')"
          :image="'chevron-down.svg'"
      />
      <div
          class="AsideMenu-Btns"
      >
        <div class="aside-wrapper">
          <div class="box box--aside">
            <button type="button" @click="setLocale('en')" :class="{active: this.$i18n.locale === 'en'}">
              EN
            </button>
            <button type="button" @click="setLocale('pl')" :class="{active: this.$i18n.locale === 'pl'}">
              PL
            </button>
            <button type="button" @click="setLocale('tr')" :class="{active: this.$i18n.locale === 'tr'}">
              TR
            </button>
          </div>
          <div class="aside-wrapper-btns">
            <BaseButton
                class="AsideMenu-Btn"
                @click.native="showModal(getModalList, true), $emit('close-menu')"
            >
              {{$t('modal.login')}}
            </BaseButton>
            <BaseButton
                class="AsideMenu-Btn"
                :button-grey="true"
                @click.native="showModal(getModalList), $emit('close-menu')"
            >
              {{$t('modal.signUp')}}
            </BaseButton>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Logo from "./Logo";
import {showModal} from "../assets/utils/utils";
import {mapGetters} from "vuex";
import Cookies from "js-cookie";

export default {
  name: "AsideMenu",
  components: {Logo, NavBar},
  computed: {
    ...mapGetters(['getModalList']),
  },
  methods: {
    showModal,
    setLocale(locale) {
      this.$i18n.locale = locale;
      Cookies.set('locale', locale)
      // window.location.href = window.location.pathname + `${locale}`;
      this.$router.push({
        params: {lang: this.$i18n.locale}
      })
      this.$router.go(0)
    }
  }

}
</script>

<style lang="scss">
.aside-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-bottom: 30px;
}

.AsideMenu {
  position: fixed;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  overflow-y: auto;
  //background-color: var(--color-body);
  background-image: url(~@/assets/images/aside-menu-bg.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  scrollbar-width: thin;

  &-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 19px;

    @media (min-width: $screen-l) {
      padding: 32px 0;
    }
  }

  &-Btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }

  &-Btn {
    width: 125px;
    height: 47px;

    &:last-child {
      margin-left: 27px;
    }
  }
}
</style>
