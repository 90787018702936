<template>
  <div class="Brands">
    <div class="Wrapper">
      <BaseH2 class="Title--dark Brands-Title">
        {{ $t('home-page.brand.title') }}
      </BaseH2>
      <BaseSubtitle class="Brands-Subtitle" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
        {{ $t('home-page.brand.subtitle') }}
      </BaseSubtitle>
      <div
          v-for="(brand, $brand) in getBrands"
          :key="$brand"
          class="Brands-Content"
      >
        <div class="Brands-ImgBox">
          <img :src="require(`@/assets/images/${logo(brand)}`)" alt="" class="Brands-Img">
        </div>
        <div class="Brands-Info">
          <div class="Brands-Benefits">
            <div class="Bonuses">
              <div class="Bonus">
                <h6>
                  {{ $t('home-page.brand.caption2') }}
                </h6>
                <p v-html="brand.welcomeBonus"></p>
              </div>
              <div class="License">
                <h6>
                  {{ $t('home-page.brand.caption3') }}
                </h6>
                <p v-html="brand.license"></p>
              </div>
              <div class="Games">
                <h6>
                  {{ $t('home-page.brand.caption4') }}
                </h6>
                <p v-html="brand.games"></p>
              </div>
            </div>
          </div>
          <BaseLink
              :link="brand.link"
              :button-red="true"
              :image="'red-arrow.svg'"
              class="Brands-Link"
          >
            <template v-if="$screen.width > 960">
              {{ $t('home-page.brand.link') }}
            </template>
            <template v-else>
              {{ $t('home-page.brand.link2') }}
            </template>
          </BaseLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Brands",
  computed: {
    ...mapGetters(['getBrands'])
  },
  methods: {
    logo(logo) {
      return this.$screen.width > 768 ? logo.logo : logo.logoMob
    }
  }
}
</script>

<style lang="scss">
.Brands {
  padding: 33px 0 40px;

  @media (min-width: $screen-l) {
    padding: 40px 0 80px;
  }

  @media (min-width: $screen-xl) {
    padding: 51px 0 100px;
  }


  &-Title {
    @media (min-width: $screen-m) {
      text-align: center;
    }
  }

  &-Subtitle {
    padding-right: 20px;
    @media (min-width: $screen-m) {
      padding-right: 0;
      text-align: center;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-xl) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &-ImgBox {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 21px;
    background-image: url(~@/assets/images/brand-casino-mobile.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;

    @media (min-width: $screen-m) {
      height: 460px;
      background-image: url(~@/assets/images/brand-casino-bg.png);
    }

    @media (min-width: $screen-xl) {
      width: 526px;
      height: 512px;
      margin-bottom: 0;
    }
  }

  &-Img {
    width: 176px;

    @media (min-width: $screen-m) {
      width: 300px;
    }
  }

  &-Info {
    display: flex;
    flex-direction: column;
    max-width: 670px;
    width: 100%;

    @media (min-width: $screen-xxl) {
      max-width: 750px;
    }
  }

  &-Benefits {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    background: var(--color-bg-white);
    border: 5px solid var(--color-border);
  }

  .Countries {
    width: 100%;
    padding: 42px 10px;
    border-bottom: 5px solid var(--color-border);

    > h6 {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 26px;
      line-height: 1;
      text-align: center;
      color: var(--color-text-ghost2);

      @media (min-width: $screen-m) {
        font-size: 36px;
      }
    }

    &-Flags {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 6px;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: $screen-m) {
        justify-content: center;
        flex-wrap: wrap;
        width: 420px;
        margin: auto;
      }

      > div {
        display: flex;
        align-items: center;
        padding: 4px 32px 4px 12px;
        background: var(--color-bg-flag);
        gap: 12px;

        @media (min-width: $screen-m) {
          padding: 15px 43px 15px 16px;
        }
      }

      img {
        width: 16px;

        @media (min-width: $screen-m) {
          width: 28px;
        }
      }

      span {
        font-family: 'KomikaTitle', sans-serif, monospace;
        font-size: 22px;
        line-height: 1;
        text-transform: uppercase;
        color: var(--color-text-dark);

        @media (min-width: $screen-m) {
          font-size: 32px;
        }
      }
    }

  }

  .Bonuses {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-m) {
      flex-direction: row;
    }

    h6 {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      color: var(--color-text-ghost2);
    }

    p {
      font-family: 'KomikaTitle', sans-serif, monospace;
      font-size: 28px;
      line-height: 1.2;
      text-align: center;
      text-transform: uppercase;
      color: var(--color-text-dark);

      @media (min-width: $screen-m) {
        font-size: 38px;
      }
    }
  }

  .Bonus {
    padding: 30px 15px;

    @media (min-width: $screen-m) {
      padding: 46px 22px;
    }

    > p {
      white-space: nowrap;
      color: var(--color-text-gold);
    }
  }

  .License {
    padding: 30px 15px;
    border-top: 5px solid var(--color-border);
    border-bottom: 5px solid var(--color-border);

    @media (min-width: $screen-m) {
      padding: 46px 22px;
      border-left: 5px solid var(--color-border);
      border-right: 5px solid var(--color-border);
      border-top: none;
      border-bottom: none;
    }
  }

  .Games {
    padding: 30px 15px;

    @media (min-width: $screen-m) {
      padding: 46px 22px;
    }
  }

  &-Link {
    width: 100%;
    height: 50px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: $screen-l) {
      width: 266px;
      height: 64px;
      left: 0;
      transform: translate(0, 0);
    }
  }
}
</style>
