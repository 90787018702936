<template>
  <div id="app">
    <MainMenu/>
    <router-view/>
    <Footer />
  </div>
</template>

<style lang="scss">

</style>
<script>
import MainMenu from "./components/MainMenu";
import Footer from "./components/Footer";
import Cookies from 'js-cookie';

export default {
  components: {Footer, MainMenu},

  created() {
    if(Cookies.get('locale') === undefined) {
      Cookies.set('locale', 'en')
      this.$router.push('/en')
      this.$i18n.locale = 'en'
    }
  }
}
</script>
