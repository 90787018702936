<template>
  <section class="Testimonials">
    <div class="Wrapper">
      <BaseH2 class="Title--dark Testimonials-Title">
        {{$t('home-page.testimonials.title')}}
      </BaseH2>
      <BaseSubtitle class="Brands-Subtitle" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
        {{$t('home-page.testimonials.subtitle')}}
      </BaseSubtitle>

      <TestimonialsSlider
          :testimonials="testimonialsReversed"
      />
    </div>
  </section>
</template>

<script>
import TestimonialsSlider from "./TestimonialsSlider";
import {mapGetters} from "vuex";

export default {
  name: "Testimonials",
  components: {TestimonialsSlider},
  computed: {
    ...mapGetters({
      testimonials: 'getTestimonials'
    }),
    testimonialsReversed() {
      return [...this.testimonials].reverse();
    }
  }
}
</script>

<style lang="scss">
.Testimonials {
  padding: 0 0 60px;

  @media (min-width: $screen-m) {
    padding: 0 0 100px;
  }

  @media (min-width: $screen-l) {
    padding: 0 0 150px;
  }

  @media (min-width: $screen-xl) {
    padding: 0 0 200px;
  }

  &-Title {
    @media (min-width: $screen-m) {
      text-align: center;
    }
  }
}
</style>
