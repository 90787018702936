<template>
  <div
      class="BenefitsContent"
  >
    <template v-if="$screen.width > 960">
      <div
          v-for="(benefit, $benefitIndex) in benefits"
          :key="$benefitIndex"
          class="BenefitsContent-Wrapper"
      >
        <img
            :src="require(`@/assets/images/${benefit.icon}`)"
            alt="icon"
            class="BenefitsContent-Icon"
            :class="benefit.iconClass"
        >
        <div
            class="Text BenefitsContent-Text"
            :class=" benefit.textClass"
        >
          {{ benefit.text }}
        </div>
      </div>
    </template>
    <template v-if="$screen.width < 960">
      <BenefitsSlider :benefits="benefits"/>
    </template>
  </div>
</template>

<script>
import BenefitsSlider from "./BenefitsSlider";

export default {

  name: "BenefitsContent",
  components: {
    BenefitsSlider
  },
  props: {
    benefits: {
      type: Array,
      default: () => {
      },
    },
  },

}
</script>

<style lang="scss">
@import 'Benefits';
</style>
