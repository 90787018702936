<template>
  <router-link :to="`/${this.$route.params.lang}`" class="logo-link">
    <img :src="require(`@/assets/images/${logo}`)" alt="logo" class="Logo Logo-Img">
  </router-link>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      logo: 'logo.svg',
    }
  },
}
</script>

<style lang="scss">
.logo-link {
  @media (max-width: 954px) {
    margin-right: auto;
  }
  @media (max-width: 760px) {
    margin-right: 10px;
  }
}
.Logo {
  width: 139px;

  @media (min-width: $screen-s) {
    width: auto;
  }
}
</style>
