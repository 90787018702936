<template>
  <footer class="Footer">
    <div class="Wrapper">
      <div class="Footer-Menu">
        <div
            v-for="(item, i) in getFooterTopLinks"
            :key="i"
            :class="item.class"
            class="Footer-MenuLinks"
        >
          <div class="Footer-MenuTitle" :class="{'font-ComicSansMS': $i18n.locale === 'pl' || 'tr'}" >
            {{ item.title }}
          </div>
          <router-link
              v-for="(link, i) in item.links"
              :key="i"
              :to="link.link"
              class="Text Text--middle Footer-Link"
          >
            {{ link.title }}
          </router-link>
          <template v-if="item.contacts">
            <div :class="{ 'Footer-MenuLinks--flex': item.addClass}">
              <a
                  v-for="(contact, $contact) in item.contacts"
                  :key="'A' + $contact"
                  :href="contact.contact" class="Text Text--middle Footer-Link Footer-Link--decorate"
                  :target="contact.icon ? '_blank' : ''"
                  :class="{'Footer-Link--icon': item.addClass}"
              >
                <img
                    v-show="contact.icon"
                    :src="contact.icon"
                    alt="social icon"
                >
                {{ contact.title }}
              </a>
            </div>
          </template>
        </div>
      </div>
      <div class="Footer-Menu Footer-NavBottom">
        <div class="Text Text--middle Footer-Copy">
          {{ $t('home-page.footer.copyright1') + currentYear + $t('home-page.footer.copyright2') }}
        </div>
        <div class="Footer-Links Footer-BottomLinks">
          <!--          <router-link-->
          <!--              v-for="(link, i) in getFooterBottomLinks"-->
          <!--              :key="i"-->
          <!--              :to="link.link"-->
          <!--              class="Text Text&#45;&#45;middle Footer-Link Footer-BottomLink"-->
          <!--          >-->
          <!--            {{ link.title }}-->
          <!--          </router-link>-->
          <router-link class="Text Text--middle Footer-Link Footer-BottomLink" :to="`/cookie-policy/${$i18n.locale}`">
            {{$t('footer.link1')}}
          </router-link>
          <router-link class="Text Text--middle Footer-Link Footer-BottomLink" :to="`/privacy-policy/${$i18n.locale}`">
            {{$t('footer.link2')}}
          </router-link>
          <router-link class="Text Text--middle Footer-Link Footer-BottomLink"
                       :to="`/terms-and-conditions/${$i18n.locale}`">
            {{$t('footer.link3')}}
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      icon: "inst.svg",
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapGetters(['getFooterBottomLinks', 'getFooterTopLinks',]),
  }
}
</script>

<style lang="scss">
.Footer {
  padding: 30px 0;
  border-top: 5px solid var(--color-bg-dark);
  background-image: url(~@/assets/images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  @media (min-width: $screen-l) {
    padding: 50px 0 40px;
    border-top: 6px solid var(--color-bg-dark);
  }

  @media (min-width: $screen-xl) {
    padding: 60px 0;
  }

  &-Menu {
    display: flex;
    flex-direction: row;
    //padding: 28px 0 30px;
    margin-bottom: 36px;
    row-gap: 35px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    @media (min-width: $screen-xs) {

    }

    @media (min-width: $screen-l) {
      // padding: 40px 0 82px;

      margin-bottom: 48px;
    }

  }

  &-MenuLinks {
    margin-bottom: 25px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      margin-right: 0;
    }

    @media (min-width: $screen-xs) {
      margin-right: 60px;
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      margin-right: 120px;
    }

    &--flex {
      display: flex;
      align-items: center;
    }
  }

  &-MenuTitle {
    font-family: 'KomikaTitle', sans-serif, monospace;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 20px;

    @media (min-width: $screen-m) {
      margin-bottom: 24px;
    }
  }

  &-Link {
    display: block;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.03em;
    color: var(--color-text-light);
    transition: .3s all;

    &:hover, &:active {
      color: var(--color-text-dark);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--decorate {
      text-decoration: underline;
      color: var(--color-text-dark) !important;
    }

    &--icon {
      margin-right: 13px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-NavBottom {
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    border-top: 2px solid var(--color-bg-ghost);

    @media (min-width: $screen-l) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-BottomLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-l) {


    }
  }

  &-Copy {
    margin-top: 28px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.03em;
    text-align: center;
    color: var(--color-text-light);
    order: 4;

    @media (min-width: $screen-l) {
      order: 0;
      margin-top: 0;
      //margin-bottom: 28px;
      text-align: left;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 0;
    }
  }

  &-BottomLink {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 13px !important;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: $screen-m) {
      font-size: 18px !important;
    }

    @media (min-width: $screen-l) {
      justify-content: center;
      margin-right: 109px;
    }
  }
}
</style>
