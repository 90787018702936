//import BaseModal from "@/components/BaseModal";

export function scrollTo(nav) {
    const el = document.getElementById(`${nav}`)
    if (!nav) {
        return
    }

    el.scrollIntoView({
        block: "start",
        behavior: "smooth",
    });

}

export function showModal(modalInfo, logIn) {
    this.$modal.show(
        {
            template: `<BaseModal :modal-info="modalInfo" :is-log-in="logIn"/>`,
            props: ['modalInfo', 'logIn'],
        },
        {modalInfo, logIn},
        {
            name: 'Modal',
            width: this.$screen.width > 590 ? 573 : 288,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            clickToClose: true,
        },
        {},
    );
}
