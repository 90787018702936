<template>
  <div class="NavBar">
    <!--    <router-link-->
    <!--        v-for="(link, $linkIndex) in getNav"-->
    <!--        :key="$linkIndex"-->
    <!--        :to="link.link"-->
    <!--        class="NavBar-Route"-->
    <!--    >-->
    <!--      <span>-->
    <!--         {{ link.title }}-->
    <!--      </span>-->
    <img v-if="image"
         :src="require(`@/assets/images/${image}`)" alt="" class="NavBar-RouteImg">
    <!--    </router-link>-->
    <router-link class="NavBar-Route" :to="`/${$i18n.locale}`">
      {{ $t('home-page.header.header-nav.HOME') }}
    </router-link>
    <router-link class="NavBar-Route" :to="`/faq/${$i18n.locale}`">
      {{ $t('home-page.header.header-nav.FAQ') }}
    </router-link>
    <router-link class="NavBar-Route" :to="`/terms-and-conditions/${$i18n.locale}`">
      {{ $t('home-page.header.header-nav.TERMS') }}
    </router-link>
    <router-link class="NavBar-Route" :to="`/contact-us/${$i18n.locale}`">
      {{ $t('home-page.header.header-nav.CONTACT-US') }}
    </router-link>


  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "NavBar",
  props: {
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(['getNav'])
  }
}
</script>

<style lang="scss">
.NavBar {
  display: flex;
  flex-direction: column;

  @media (min-width: 961px) {
    flex-direction: row;
    align-items: center;
  }

  &-Route {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 16px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    border-bottom: 1px solid var(--color-border2);
    color: var(--color-text-light);
    transition: .3s all;

    &:last-child {
      border-bottom: none;
    }

    &:active {
      color: var(--color-text-dark);
    }

    @media (min-width: 961px) {
      display: block;
      margin: 0 41px 0 0;
      padding: 0;
      font-size: 16px;
      text-transform: uppercase;
      border-bottom: none;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 5px;
        background-color: transparent;
        transition: .3s all;
      }

      &:hover,
      &:active {
        color: var(--color-text-dark);

        &:before {
          background-color: var(--color-text-dark);
        }
      }

      &:last-child {
        margin-right: 0;
      }

    }

    @media (min-width: $screen-xl) {
      margin: 0 71px 0 0;
    }
  }
}
</style>
