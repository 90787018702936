<template>
  <section class="Benefits">
    <div class="Wrapper">
      <BaseH2 class="Title--dark Benefits-Title">
        {{ $t('home-page.benefits.title') }}
      </BaseH2>
      <BaseSubtitle class="Benefits-Subtitle" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
        {{ $t('home-page.benefits.subtitle') }}
      </BaseSubtitle>
      <BenefitsContent
          :benefits="getBenefits"
          class="Benefits-Content"
      />
    </div>
  </section>
</template>

<script>
import BenefitsContent from "./BenefitsContent";
import {mapGetters} from "vuex";

export default {
  name: "Benefits",
  components: {BenefitsContent},
  computed: {
    ...mapGetters(['getBenefits'])
  }
}
</script>

<style lang="scss">
.Benefits {
  padding: 33px 0 40px;

  @media (min-width: $screen-l) {
    padding: 40px 0 80px;
  }

  @media (min-width: $screen-xl) {
    padding: 51px 0 100px;
  }

  &-Title {
    @media (min-width: $screen-m) {
      text-align: center;
    }
  }

  &-Subtitle {
    padding-right: 20px;
    @media (min-width: $screen-m) {
      padding-right: 0;
      text-align: center;
    }
  }

  &-Content {

    @media (min-width: $screen-l) {
      height: 389px;
    }

    @media (min-width: $screen-xl) {
      height: 549px;
    }
  }
}
</style>
