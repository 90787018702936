import Vue from 'vue'
import VueI18n from "vue-i18n";
import {defaultLocale, languages} from '@/plugins/locales'

const CookiesLang = window.location.pathname.split('/').at(-1)

Vue.use(VueI18n)

const messages = Object.assign(languages)

export const i18n = new VueI18n({
    locale: CookiesLang || defaultLocale,
    fallbackLocale: 'en',
    messages
})
