<template>
  <div class="BaseTabs">
    <button
      v-for="tab in items"
      :key="tab.value"
      class="BaseTabs-Tab"
      :class="{ 'BaseTabs-Tab--active': currentItem === tab.value }"
      @click="$emit('choose-tab', tab.value)"
    >
      <div class="BaseTabs-Name">
        {{ tab.name }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItem: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style lang="scss">
.BaseTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $screen-m) {
    justify-content: center;

    margin-bottom: 46px;
  }

  &-Tab {
    padding-bottom: 5px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.03em;
    color: var(--color-text-main);
    transition: 0.3s ease;
    font-family: 'KomikaText', sans-serif, monospace;
    cursor: pointer;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: $screen-m) {
      margin-right: 34px;
      font-size: 21px;
    }

    @media (min-width: $screen-xl) {
      margin-right: 70px;
      font-size: 24px;
    }

    &--active {
      color: var(--color-bg-red);
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        height: 3px;
        background-color: var(--color-bg-red);
      }
    }
  }
}
</style>
