<template>
  <div class="Home">
    <TheHeader/>
    <About />
    <Benefits />
    <Brands />
    <WorkOrder />
    <Testimonials />
  </div>
</template>

<script>

import TheHeader from "../components/TheHeader";
import About from "../components/About";
import Benefits from "../components/Benefits/Benefits";
import Brands from "../components/Brands";
import WorkOrder from "../components/WorkOrder";
import Testimonials from "../components/Testimonials/Testimonials";

export default {
  name: 'Home',
  components: {
    Testimonials,
    WorkOrder,
    Brands,
    About,
    TheHeader,
    Benefits,
  }
}
</script>
