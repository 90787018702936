<template>
  <h2 class="Title Title--h2">
    <span>
      <slot/>
    </span>
    <span class="Title--shadow">
      <slot/>
    </span>
  </h2>
</template>

<script>
export default {
  name: "BaseH2",
  props: {

  },
}
</script>

<style scoped >

</style>