<template>
  <h3 class="Title Title--h3">
    <span>
      <slot/>
    </span>
    <span class="Title--shadow">
      <slot/>
    </span>
  </h3>
</template>

<script>
export default {
  name: "BaseH3",
  props: {

  },
}
</script>

<style scoped >

</style>