<template>
  <div class="TestimonialsSlider">
    <swiper
        :options="swiperOption"
        class="TestimonialsSlider-Slider"
    >
      <swiper-slide
          v-for="(testimonial, index) in testimonials"
          :key="index"
          class="TestimonialsSlider-Slide"
      >
        <a
            :href="testimonial.href"
            target="_blank"
            class="TestimonialsSlider-Logo">
          <img
              :src="require(`@/assets/images/testimonials-logo/${testimonial.logo}`)"
               alt="logo"
              :style="{width: logoWidth(testimonial)}"
          >
          <img v-show="$screen.width < 960"
               src="@/assets/images/five-stars-mob.svg" alt="rating stars">
        </a>
        <div class="TestimonialsSlider-Info">
          <p
              class="Text Text--ghost"
              v-html="testimonial.text"
          ></p>
          <img v-show="$screen.width > 960"
              src="@/assets/images/five-stars.svg" alt="rating stars">
        </div>
      </swiper-slide>
      <div v-show="$screen.width < 960"
           class="swiper-pagination-testimonial"
           slot="pagination"
      ></div>
      <div v-show="$screen.width > 960"
           class="button-prev"
           slot="button-prev">
        <img src="@/assets/images/chevron-left.svg" alt="">
      </div>
      <div v-show="$screen.width > 960" class="button-next" slot="button-next">
        <img src="@/assets/images/chevron-right.svg" alt="">
      </div>
    </swiper>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'

export default {
  name: "TestimonialsSlider",
  props: {
    testimonials: {
      type: Array,
      default: () => {
      }
    },
  },
  data() {
    return {
      swiperOption: {
        modules: [Pagination,Navigation],
        spaceBetween: 20,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination-testimonial',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '</span>';
          }
        },
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev'
        }
      }
    }
  },
  methods: {
    logoWidth({ width = '' }) {
      return `${ width }%`;
    },
  }
}
</script>

<style lang="scss">
.TestimonialsSlider {
  position: relative;


  &-Slider {

    overflow-x: hidden;

    @media (min-width: $screen-xl) {
      width: 1100px;
      height: 356px;
      margin: 0 auto;
    }

    @media (min-width: $screen-xxl) {
      width: 1200px;
      height: 394px;
    }
  }

  &-Slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-xl) {
      flex-direction: row;
    }
  }

  &-Logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    margin-bottom: 16px;
    background-image: url(~@/assets/images/testimonial-logo-bg-mob.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (min-width: $screen-m) {
      height: 394px;
      background-image: url(~@/assets/images/testimonial-logo-bg.png);
    }

    @media (min-width: $screen-xl) {
      width: 519px;
      height: 100%;
      margin-bottom: 0;
    }

    img {
      &:first-child {
        width: 80%;
        max-height: 70px;
        object-fit: contain;
        margin-bottom: 18px;
      }

      @media (min-width: $screen-m) {
        &:first-child {
          width: 40%;
          max-height: 150px;
          object-fit: contain;
          margin-bottom: 18px;
        }
      }

      @media (min-width: $screen-l) {
        &:first-child {
          margin-bottom: 0;
        }
      }

      @media (min-width: $screen-xl) {
        &:first-child {
          width:80%;
          margin-bottom: 0;
        }
      }
    }
  }

  &-Info {
    position: relative;
    width: 100%;
    height: 370px;
    padding: 24px;
    overflow-y: auto;
    box-sizing: border-box;
    background: var(--color-bg-white);
    border: 5px solid var(--color-border);

    @media (min-width: $screen-m) {
      width: 519px;
      height: 100%;
      padding: 40px;
    }

    @media (min-width: $screen-xl) {
      width: 640px;
      height: 100%;
      padding: 76px 76px 10px;
    }

    p {
      @media (min-width: $screen-l) {
        margin-bottom: 40px;
      }

      @media (min-width: $screen-xl) {
        margin-bottom: 0;
      }
    }

    img {
      @media (min-width: $screen-xl) {
        position: absolute;
        left: 76px;
        bottom: 40px;
      }

      @media (min-width: $screen-xxl) {
        bottom: 72px;
      }
    }
  }

  .button-prev,
  .button-next {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translate(0,-50%);
    cursor: pointer;
  }
  .button-prev {
    left: 0;
  }
  .button-next {
    right: 0;
  }
  .swiper-pagination-testimonial {
    text-align: center;
  }
}
</style>
